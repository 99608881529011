import React from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';

interface PropsI {
  fields: any[];
  rows: any[];
  border?: boolean;
  pillRows?: boolean;
  debug?: boolean;
}

const BasicTable = (props: PropsI) => {
  const { fields, rows } = props;
  const fullWidth = fields.reduce((accumulator, f) => accumulator + f.width, 0);
  props.debug && console.log('BasicColumnTable | {fields, rows}', fields, rows);
  return (
    <BasicTableContents key={'basicTable'}>
      <TableContents key={'basicTable_contents'}>
        <HeaderRow key={'basicTable_header_row'}>
          {fields.map((field, cellIndex) => (
            <HeaderCell
              key={`basicTable_header_cell_${cellIndex}`}
              width={field.width}
            >
              {field.label}
            </HeaderCell>
          ))}
        </HeaderRow>
        {rows.length ? (
          rows.map((row, index) => (
            <BodyRow key={`basicTable_row_${index}`} index={index}>
              {fields.map((field, cellIndex) => (
                <BodyCell
                  key={`basicTable_cell_${index}_${cellIndex}`}
                  fontColor={field?.fontColor ? field.fontColor : 'secondary'}
                  width={field.width}
                >
                  {_.get(row, field.value, '')}
                </BodyCell>
              ))}
            </BodyRow>
          ))
        ) : (
          <BodyRow key={'basicTable_row_empty'} index={0}>
            <BodyCell width={fullWidth}>No data is available</BodyCell>
          </BodyRow>
        )}
      </TableContents>
    </BasicTableContents>
  );
};

BasicTable.defaultProps = {
  debug: false,
  border: false,
  pillRows: false,
};

export default BasicTable;

const BasicTableContents = styled.div`
  width: max-content;
  height: max-content;
  display: flex;
`;

export const TableContents = styled.div<{
  border?: boolean;
}>`
  font-size: 12px;
  border-radius: 8px;
  border: ${(p) => (p.border ? 1 : 0)}px solid
    ${(p) => p.theme.palette.accentPrimary};
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
`;
TableContents.defaultProps = {
  border: false,
};

export const HeaderRow = styled.div<{
  highlight?: boolean;
}>`
  display: flex;
  font-size: 14px;
  margin-bottom: 3px;
  flex-direction: row;
  justify-content: center;
  color: ${(p) =>
    p.highlight
      ? p.theme.palette.backgroundPrimary
      : p.theme.palette.textPrimary};
  border-radius: 8px;
  background-color: ${(p) =>
    p.highlight
      ? p.theme.palette.accentPrimary
      : p.theme.palette.backgroundQuaternary};
`;
HeaderRow.defaultProps = {
  highlight: false,
};

export const HeaderCell = styled.div<{ width: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: ${(p) => p.width}px;
`;

export const BodyRow = styled.div<{ index: number }>`
  display: flex;
  flex-direction: row;
  margin-bottom: 3px;
  border-radius: 8px;
  &:nth-of-type(odd) {
    background-color: ${(p) => p.theme.palette.backgroundSecondary};
  },
  &:nth-of-type(even) {
    background-color: ${(p) => p.theme.palette.backgroundTertiary};
  },
  :hover {
    cursor: default;
    background-color: ${(p) => p.theme.palette.backgroundQuaternary};
  }
}
`;

export const BodyCell = styled.div<{
  width: number;
  fontColor?: string;
  fontSize?: number;
  leftBorder?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(p) => p.width}px;
  color: ${(p) =>
    p.fontColor === 'primary'
      ? p.theme.palette.textPrimary
      : p.theme.palette.textSecondary};
  font-size: ${(p) => p.fontSize}px;
  text-align: center;
  ${(p) =>
    p.leftBorder &&
    `
    border-left: 1px solid ${p.theme.palette.backgroundPrimary};
  `}
`;

BodyCell.defaultProps = {
  fontColor: 'secondary',
  fontSize: 12,
  leftBorder: true,
};
